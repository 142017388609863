/* global Component, google, MarkerClusterer */
let maps_loading = null;
class GoogleMapComponent extends Component{

    static name() {
        return "googleMapComponent";
    }

    static componentName() {
        return "googleMapComponent";
    }

    getProps() {
        return {
            'latitude':{
                type:Number,
                default:null
            },
            'longitude':{
                type:Number,
                default:null
            },
            'markers':{
                type: Array
            },
            'paths':{
                type:Object,
                default:null
            },
            'record':{
                type:Object,
                default:null
            },
            "prefix":{
                type:String,
                default:''
            },
            "editMode":{
                type:Boolean,
                default:false
            },
            "customZoom":{
                type:Number,
                default:17
            },
            "runNormalize":{
                type:String,
                default:null
            },
            saveChangeCallback:{
                type:Function
            }
        };
    }

    getWatch() {
        return {
            latitude() {
                this.lat = this.latitude;
                this.map.setCenter({lat: this.lat, lng: this.lng});
            },
            longitude() {
                this.lng = this.longitude;
                this.map.setCenter({lat: this.lat, lng: this.lng});
            },
            record() {
                this.rec = this.record;
                this.initMap();
            },
            markers: {
                handler: function (val, oldval) {
                    this.marks = this.markers ? this.markers : [];
                    this.updateMarkers();
                },
                deep: true
            },
            paths: {
                handler: function (val, oldval) {
                    this.map_paths = this.paths || {};
                    this.updatePaths();
                },
                deep: true
            }
        };
    }

    getData(){
        return this.data;
    }

    data(){
        return {
            lat:this.latitude,
            lng:this.longitude,
            marks: this.markers || [],
            selectedMarket:null,
            map_paths: this.paths || {}
        };
    }

    mounted() {
        return async function () {
            window.$map = this;
            if (!maps_loading && (!window.google || !window.google.maps || !window.google.maps.Map)) {
                maps_loading = Promise.pending();
                await $.getScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyBWDkCk3f7KvH4r_z3UtyOkF_pi_BiOLig&libraries=geometry');
                await $.getScript('https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js');
                maps_loading.resolve();
            }
            if (maps_loading) await maps_loading.promise;
            this.map = null;
            this.gmarkers = null;
            this.initMap();
        };
    }

    getComputed(){
        return {
            style() {
                return {
                    width: '100%',
                    height: '500px',
                };
            }
        };

    }

    getMethods() {
        return {
            round: this.round,
            normalizeAddress:this.normalizeAddress,
            clientNormalize:this.clientNormalize,
            handleEvent:this.handleEvent,
            initMap:this.initMap,
            updateMapBounds:this.updateMapBounds,
            createMarker:this.createMarker,
            updateMarkers:this.updateMarkers,
            updatePaths:this.updatePaths
        };
    }

    normalizeAddress(){
        let comma = '';
        let postfix = '';
        let fprefix = this.prefix;
        let record = this.record;
        if (record[fprefix + 'Street']) {
            postfix += "calle " + record[fprefix + 'Street'];
        }
        if (record[fprefix + 'StreetNumber']) {
            postfix += " " + record[fprefix + 'StreetNumber'];
        }
        if (record[fprefix + 'Locality']) {
            if (record[fprefix + 'City'] && record[fprefix + 'City'].toLowerCase().search(/buenos aires/) < 0 && record[fprefix + 'City'].toLowerCase().search(/caba/) < 0) {
                // si la ciudad es buenos aires (o caba), google no toma bien que le manden como localidad "comuna 1" por ejemplo
                comma = ', ';
                postfix += comma + record[fprefix + 'Locality'];
            }
        }
        if (record[fprefix + 'City'] !== record[fprefix + 'Locality']) {
            comma = ', ';
            postfix += comma + record[fprefix + 'City'];

        }
        if (record[fprefix + 'Province'] && (record[fprefix + 'Province'] !== record[fprefix + 'Locality'] && record[fprefix + 'Province'] !== record[fprefix + 'City'])) {
            comma = ', ';
            postfix += comma + record[fprefix + 'Province'];
        }
        if (record[fprefix + 'Country']) {
            comma = ', ';
            if(record[fprefix + 'CountryName'])
                postfix += comma + record[fprefix + 'CountryName'];
            else
                postfix += comma + record[fprefix + 'Country'];
        }
        record.normalizeAddress =  postfix;
        return record.normalizeAddress;
    }

    async clientNormalize(){
        let self = this;
        self.normalizeAddress();
        if(this.record[this.prefix + "normalizeAddress"])
            return new Promise(function (resolve, reject) {
                let geocoder = new google.maps.Geocoder();
                geocoder.geocode({
                    'address':self.record[self.prefix + "normalizeAddress"]
                }, function (results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                        self.lat = results[0].geometry.location.lat();
                        self.lng = results[0].geometry.location.lng();
                        self.record[self.prefix + "Latitude"] = self.lat;
                        self.record[self.prefix + "Longitude"] = self.lng;
                        self.record.normalize = false;
                        resolve(status);
                    } else {
                        resolve(status);
                    }
                });
            });
        return null;
    }

    handleEvent(event) {
        this.lat = event.latLng.lat();
        this.lng = event.latLng.lng();
        if(this.record && this.editMode){
            this.record[this.prefix + "Latitude"] = this.lat;
            this.record[this.prefix + "Longitude"] = this.lng;
        }
        if(this.saveChangeCallback)
            this.saveChangeCallback(this.lat,this.lng);
    }

    async initMap() {
        if (this.runNormalize || this.record.normalize)
            await this.clientNormalize();
        let lat = this.lat !== null ? this.lat : this.record[this.prefix + "Latitude"];
        let lng = this.lng !== null ? this.lng : this.record[this.prefix + "Longitude"];

        let opts = {zoom:Number(this.customZoom)};
        let calculate_bounds = false;

        if (isNaN(lat) || isNaN(lng) || lat === null || lng === null) {
            calculate_bounds = true;
            lat = lat || 0;
            lng = lng || 0;
        }
        opts.center = {lat, lng};
        if(this.customZoom)
            opts.zoom = Number(this.customZoom);
        //console.log("OPTS", opts)
        this.infowindow = new google.maps.InfoWindow({});

        this.map = new google.maps.Map(this.$refs.map,opts);
        this.gmarkers = {};
        this.marks = this.markers ? this.markers : [];
        this.gpolys = {};

        if (this.record &&  typeof this.record[this.prefix + "Latitude"] != 'undefined' &&  typeof this.record[this.prefix + "Longitude"] != 'undefined') {
            this.marks.push({latitude: this.record[this.prefix + "Latitude"], longitude : this.record[this.prefix + "Longitude"], label: '', title: ''});
        }

        for (let m of this.marks) {
            let marker = this.createMarker({lat: m.latitude, lng: m.longitude}, this.map, m.label, m.title, m.info,this.editMode);

            if(this.editMode) {
                marker.addListener('drag', this.handleEvent);
                marker.addListener('dragend', this.handleEvent);
            }

            let self = this;
            let infowindow = new google.maps.InfoWindow();
            google.maps.event.addListener(marker, 'mouseover', (function (marker) {
                return function () {
                    let content = `${self.record.Address}, ${self.record.City}`;
                    infowindow.setContent(content);
                    infowindow.open(self.map, marker);
                };
            })(marker));

            if (m.id) {
                this.gmarkers[m.id] = marker;
            }

        }


        for (let pathkey of Object.keys(this.map_paths)) {
            let path = this.map_paths[pathkey];
            let poly = new google.maps.Polyline({
                strokeColor: '#CC0099',
                strokeOpacity: 1.0,
                strokeWeight: 3,
                geodesic: true,
                map: this.map,
            });
            poly.setPath(path.map(p => { return {lat: p.latitude, lng: p.longitude};}));
            this.gpolys[pathkey] = poly;
        }

        // Add a marker clusterer to manage the markers.
        this.markerCluster = new MarkerClusterer(this.map, Object.values(this.gmarkers), {maxZoom: 17, imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
        if (calculate_bounds)
            this.updateMapBounds();

    }

    updateMapBounds() {
        let bounds = new google.maps.LatLngBounds();
        for (let marker of Object.values(this.gmarkers)) {
            bounds.extend(marker.position);
        }
        this.map.fitBounds(bounds);
    }

    createMarker(position, map, label, title, info,draggable = false) {
        let self = this;
        let marker = new google.maps.Marker({position,map, label, title, draggable});
        if (info) {
            marker.addListener('click', function () {
                self.infowindow.setContent(info);
                self.infowindow.open(map, marker);
            });
        }
        return marker;

    }

    round(value, decimals = 0) {
        let res;
        if (('' + value).includes('e')) { // para valores del tipo 1e-8
            value = Number(value).toFixed(20);
        }
        let v1 = Math.round(value + 'e+' + decimals);
        if (v1.toString().includes("e")) {
            // eslint-disable-next-line no-unused-vars
            let [numericValue, e_value] = v1.toString().split('e');
            e_value = Number(e_value);
            e_value += -decimals;
            res = Number(Number(Math.round(value + 'e' + e_value) + "e-" + e_value).toFixed(decimals));
        } else {
            let v2 = 'e-' + decimals;
            let v3 = v1 + v2;
            res = Number(Number(v3).toFixed(decimals));
        }
        if (isNaN(res) && typeof value === 'number') {
            res = Number(Number(Math.round(value.toFixed(20) + 'e' + decimals) + 'e-' + decimals).toFixed(decimals));
            if (isNaN(res) & !isNaN(value)) {
                res = parseFloat((value + 0.0000000000001).toFixed(decimals));
                if (isNaN(res)) throw new Error("Error rounding " + value + " to " + decimals + " decimals");
            }
        }
        return res;
    }

    updateMarkers() {
        let mark_ids = {};
        for (let m of this.marks) {
            let marker = this.gmarkers[m.id];
            if (marker) {
                //console.log(m.id, marker.getPosition().lat() != m.latitude, marker.getPosition().lng(), m.longitude, marker.getPosition().lng() != m.longitude, marker.getLabel() != m.label, marker.getTitle() != m.title)
                if (this.round(marker.getPosition().lat(), 4) != this.round(m.latitude,4) || this.round(marker.getPosition().lng(),4) != this.round(m.longitude,4) || marker.getLabel() != m.label || marker.getTitle() != m.title) {
                    this.markerCluster.removeMarker(marker);
                    marker.setPosition({lat: m.latitude, lng: m.longitude});
                    marker.setLabel(m.label);
                    marker.setTitle(m.title);
                    marker.setAnimation(google.maps.Animation.BOUNCE);
                    this.markerCluster.addMarker(marker);
                    setTimeout(function () {marker.setAnimation(null);}, 2000);
                }
            } else {
                marker = this.createMarker({lat: m.latitude, lng: m.longitude}, this.map, m.label, m.title, m.info);
                //new google.maps.Marker({position: {lat: m.latitude, lng: m.longitude}, map: this.map, label: m.label, title: m.title});
                if (m.id) this.gmarkers[m.id] = marker;
                marker.setAnimation(google.maps.Animation.BOUNCE);
                this.markerCluster.addMarker(marker);
                setTimeout(function () {marker.setAnimation(null);}, 2000);
            }
            mark_ids[m.id] = 1;
        }
        for (let id of Object.keys(this.gmarkers)) {

            if (!mark_ids[id]) {
                this.gmarkers[id].setMap(null);
                this.markerCluster.removeMarker(this.gmarkers[id]);
                delete this.gmarkers[id];
            }
        }
        this.updateMapBounds();
    }

    updatePaths() {
        let path_ids = {};
        for (let pathkey of Object.keys(this.map_paths)) {
            path_ids[pathkey] = 1;
            let path = this.map_paths[pathkey];
            if (this.gpolys[pathkey]) {
                this.gpolys[pathkey].setPath(path.map(p => { return {lat: p.latitude, lng: p.longitude};}));
            } else {
                let poly = new google.maps.Polyline({
                    strokeColor: '#CC0099',
                    strokeOpacity: 1.0,
                    strokeWeight: 3,
                    geodesic: true,
                    map: this.map,
                });
                poly.setPath(path.map(p => { return {lat: p.latitude, lng: p.longitude};}));
                this.gpolys[pathkey] = poly;
            }
        }
        for (let id of Object.keys(this.gpolys)) {
            if (!path_ids[id]) {
                this.gpolys[id].setMap(null);
                delete this.gpolys[id];
            }
        }
        this.updateMapBounds();
    }

    getTemplate() {
        return `<div>
                 <div ref="map" :style="style">
                 </div>
               </div>`;
    }

}
GoogleMapComponent.registerComponent();